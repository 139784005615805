import React from "react";
import TokensBurned from "./TokensBurned";
import SocialActions from "./SocialActions";
import TransactionTable from "./TransactionTable";
import BurntTokenChart from "../../../../components/BurntTokenChart";
import { useParams } from "react-router-dom";
import { useProjectById } from "../../../../hooks/useProjectById";
import ProjectStatus from "./ProjectStatus";

const ProjectDetailContent = () => {
  const { projectId } = useParams();
  const {
    data: project,
  } = useProjectById({ _id: projectId });
  return (
    <div className="flex w-11/12 flex-col gap-6 md:w-[60%]">
      <ProjectStatus />
      <TokensBurned project={project} />
      <SocialActions project={project} />
      <BurntTokenChart />
      <TransactionTable />
    </div>
  );
};

export default ProjectDetailContent;
