import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import Board from './Board';
import { useParams } from 'react-router-dom';
import { useProjectById } from '../hooks/useProjectById';
import { useBurnDataByProjectId } from '../hooks/useBurnDataByProjectId';

import axios from 'axios';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const BurntTokenChart = () => {
  const [dates, setDates] = useState([]);
  const [burnData, setBurnData] = useState([]);
  const [priceData, setPriceData] = useState([]);
  const { projectId } = useParams();
  const {
    data: project,
  } = useProjectById({ _id: projectId });

  const {
    data: burnDatas,
  } = useBurnDataByProjectId({projectId});

  const getLast14Days = () => {
    const dates = [];
    for (let i = 13; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      dates.push(date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' }));
    }
    return dates;
  };

  const processBurnData = (burnDatas) => {
    const last14Days = getLast14Days(); 
    const burnDataMap = {};
  
    burnDatas.forEach((entry) => {
      const formattedDate = new Date(entry._id).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });
      burnDataMap[formattedDate] = entry.totalBurnAmount;
    });
  
    let cumulativeSum = 0;
  
    return last14Days.map((date) => {
      cumulativeSum += burnDataMap[date] || 0;
      return cumulativeSum;
    });
  };
  

  useEffect(() => {
    setDates(getLast14Days());
    if (burnDatas && Array.isArray(burnDatas)) {
      const processedBurnData = processBurnData(burnDatas);
      setBurnData(processedBurnData);
    }
    const fetchPriceData = async () => {
      try {
        const tokenSymbol = project?.tokenSymbol?.toLowerCase(); // Assuming project has the token symbol
        if (!tokenSymbol) {
          console.error("Token symbol not found");
          return;
        }
    
        // Using environment variables for the API URL and other configurations
        const apiUrl = process.env.REACT_APP_COINGECKO_API_URL;
        const apiKey = process.env.REACT_APP_COINGECKO_API_KEY;
        const apiHeader = process.env.REACT_APP_COINGECKO_API_HEADER;
    
        const response = await axios.get(`${apiUrl}/api/v3/coins/${tokenSymbol}/market_chart`, {
          headers: {
            accept: "application/json",
            [apiHeader]: apiKey,
          },
          params: {
            vs_currency: "usd", // Assuming USD is the desired currency
            days: "14", // Fetch last 14 days of data
          },
        });
    
        // Extract daily prices
        const prices = response.data.prices.map((entry) => entry[1]); // Second element is the price
        setPriceData(prices);
      } catch (error) {
        console.error("Error fetching price data:", error);
      }
    };

    fetchPriceData();
  }, [burnDatas]);

  // const aggregateBurnsByDay = (burnAmounts) => {
  //   return burnAmounts;
  // };

  const data = {
    labels: dates,
    datasets: [
      {
        label: 'Burn',
        data: burnData,
        borderColor: '#D6983E',
        backgroundColor: '#D6983E',
        fill: false,
        stepped: true,
        yAxisID: 'y2',
        pointRadius: 3,
      },
      {
        label: 'Price',
        data: priceData.length ? priceData.slice(0, burnData.length) : [],
        borderColor: '#9C56B7',
        backgroundColor: 'rgba(200,98,248,0.1)',
        fill: true,
        tension: 0.4,
        yAxisID: 'y1',
        pointRadius: 3,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        ticks: {
          color: '#56B0B9',
        },
        grid: {
          display: false,
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'left',
        ticks: {
          color: '#9C56B7',
        },
        grid: {
          display: true,
          color: '#56B0B9',
        },
        border: {
          display: false,
        },
      },
      y2: {
        type: 'linear',
        display: true,
        position: 'right',
        ticks: {
          color: '#D6983E',
          stepSize: 25000,
          callback: function (value) {
            return value >= 1000 ? value / 1000 + 'k' : value;
          },
        },
        grid: {
          drawOnChartArea: false,
        },
        border: {
          display: false,
        },
      },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          borderWidth: 0,
        },
      },
    },
  };

  return (
    <Board className="p-6">
      <div className="flex items-center gap-4">
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/burn-chart.svg`}
          alt="burn-chart"
          className="w-9 h-9"
        />
        <span className="font-bold text-[22px]">Price vs. Burn Chart</span>
      </div>
      <Line data={data} options={options} />
    </Board>
  );
};

export default BurntTokenChart;
