import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const useBurnTransactionByProjectId = ({ projectId }) => {
  return useQuery({
    queryKey: ["burnHistory", projectId],
    queryFn: async () => {
      if (!projectId) {
        throw new Error("Project ID is required");
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/burn-history/project/${projectId}`
      );
      return data;
    },
    staleTime: 1000 * 60 * 5,
    enabled: !!projectId,
  });
};
