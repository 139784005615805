import React, { useEffect, useState } from "react";
import Board from "../../../../components/Board";
import ProgressBar from "../../../../components/ProgressBar";
import axios from "axios";

const TokensBurned = ({ project }) => {
  const tokenAddress = project.tokenAddress;
  const blockchain = "eth";
  const [burnedTokens, setBurnedTokens] = useState(0);
  const [totalCommitted, setTotalCommitted] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTokenData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        // Fetch burned tokens
        const burnedResponse = await axios.get(
          `https://deep-index.moralis.io/api/v2/${tokenAddress}/transfers`,
          {
            params: {
              chain: blockchain,
              to_block: "latest",
              to: "0x000000000000000000000000000000000000dead", // Burn address
            },
            headers: {
              "x-api-key": process.env.REACT_APP_MORALIS_API_KEY,
            },
          }
        );

        const burnedAmount = burnedResponse.data.result.reduce(
          (total, tx) => total + parseFloat(tx.value) / Math.pow(10, tx.decimals),
          0
        );

        setBurnedTokens(burnedAmount);

        // Fetch total supply
        const supplyResponse = await axios.get(
          `https://deep-index.moralis.io/api/v2/token/${tokenAddress}`,
          {
            params: { chain: blockchain },
            headers: {
              "x-api-key": process.env.REACT_APP_MORALIS_API_KEY,
            },
          }
        );

        const totalSupply = parseFloat(supplyResponse.data.total_supply) / Math.pow(10, supplyResponse.data.decimals);
        setTotalCommitted(totalSupply);
      } catch (err) {
        console.error("Error fetching token data:", err);
        setError("Failed to fetch token data.");
      } finally {
        setIsLoading(false);
      }
    };

    if (tokenAddress) {
      fetchTokenData();
    }
  }, [tokenAddress, blockchain]);

  const burnedPercentage = totalCommitted > 0 ? (burnedTokens / totalCommitted) * 100 : 0;

  return (
    <Board className="flex flex-col items-start p-6 gap-4">
      <div className="flex gap-3 items-center">
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/solar_fire-bold.svg`}
          alt="fire"
          className="w-9 h-9"
        />
        <span className="font-bold text-[22px]">Tokens burned</span>
      </div>
      {isLoading ? (
        <span>Loading...</span>
      ) : error ? (
        <span className="text-red-500">{error}</span>
      ) : (
        <>
          <div className="flex font-bold items-end gap-2">
            <span className="text-5xl text-[#FF8A00]">
              {burnedTokens.toLocaleString()}
            </span>
            <span className="text-xl text-[#56B0B9]">
              / {totalCommitted.toLocaleString()}
            </span>
          </div>
          <ProgressBar percentage={burnedPercentage} color="#FF8A00" />
        </>
      )}
    </Board>
  );
};

export default TokensBurned;
