const formatNumber = (num) => {

  if (num === null || num === undefined) return "0";
  if (num >= 1_000_000_000) {
    return `${(num / 1_000_000_000).toFixed(1)}B`;
  } else if (num >= 1_000_000) {
    return `${(num / 1_000_000).toFixed(1)}M`;
  } else if (num >= 1_000) {
    return `${(num / 1_000).toFixed(1)}K`;
  }

  if (num < 1) {
    const numStr = num.toString();
    const firstNonZero = numStr.search(/[1-9]/);
    const significantDigits = Math.max(11 - firstNonZero, 4);
    return num.toFixed(significantDigits);
  }
  return num.toLocaleString();
};

export default formatNumber;


