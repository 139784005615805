import Board from "../../../../components/Board";
import CopytoClipboard from "../../../../components/CopytoClipboard";
import ProgressBar from "../../../../components/ProgressBar";
import { useSocialAction } from "../../../../hooks/useSocialActions";
import SocialItem from "../../../ProjectDetail/SocialItem";


const SocialActions = ({project}) => {
  const { data } = useSocialAction(project?.ineractionHashtag || '');
  return (<Board className="flex flex-col items-start p-6 gap-4">
    <div className="flex justify-between w-full md:flex-row flex-col items-start">
      <div className="flex gap-3 items-center">
        <img src={`${process.env.PUBLIC_URL}/assets/icons/solar_share-bold.svg`}
          alt="fire" className="w-9 h-9" />
        <span className="font-bold text-[22px]">Social actions</span>
      </div>
      <CopytoClipboard text={project?.interactionHashtag} />
    </div>
    <div className="flex font-bold items-end gap-2">
    <span className="text-5xl text-[#00D1FF]">{data?.totalActions || 0}</span>
    <span className="text-xl text-[#56B0B9]">total actions</span>
    </div>
    <div className="flex flex-col gap-4 w-full">
    <SocialItem title='Shares' desc={`${project.twitterThresholds?.shares.burnAmount} burned per ${project.twitterThresholds?.shares.threshold}`} totalAmount={project.twitterThresholds?.shares.threshold} realAmount={data?.quotes || 0} />
      <SocialItem title='Likes' desc={`${project.twitterThresholds?.likes.burnAmount} burned per ${project.twitterThresholds?.likes.threshold}`} totalAmount={project.twitterThresholds?.likes.threshold} realAmount={data?.likes || 0} />
      <SocialItem title='Comments' desc={`${project.twitterThresholds?.comments.burnAmount} burned per ${project.twitterThresholds?.comments.threshold}`} totalAmount={project.twitterThresholds?.comments.threshold} realAmount={data?.replies || 0} />
      <SocialItem title='Retweets' desc={`${project.twitterThresholds?.retweets.burnAmount} burned per ${project.twitterThresholds?.retweets.threshold}`} totalAmount={project.twitterThresholds?.retweets.threshold} realAmount={data?.retweets || 0} />
      <SocialItem title='Content' desc="15,000 burned per 10" totalAmount={project.twitterThresholds?.shares.threshold} realAmount={99} />
      <div className="w-full flex justify-between md:flex-row flex-col items-center">
        <div className="w-full md:w-1/5 flex flex-col">
          <span>% Joined</span>
          <span className="text-[#56B0B9] text-xs">{project.takeoverThresholds?.holdersJoined.burnAmount} at {project.takeoverThresholds?.holdersJoined.threshold}%</span>
        </div>
        <div className="w-full md:w-[75%]">
          <ProgressBar
            percentage={35}
            color="#00D1FF"
            showFraction={true}
            fractionText="35%"
          />
        </div>
      </div>
    </div>

  </Board>)
}

export default SocialActions;